import React from 'react';
import './Certificates.css';
import javaScriptCertificate from '../../../assets/images/Free_Code_Camp_JavaScript.png';
import responsiveWebCertificate from '../../../assets/images/Free_Code_Camp_Responsive_Web_Design.png';
import micromasterCertificate from '../../../assets/images/MicroMaster_in_Programming_Certificate.png';

const certificates = () => {
    return (
        <div className="sections">
            <p>Below is a selection of certificates I have gained that may be of relevance.</p>
            <div className="certificatesContainer">
                <img src={micromasterCertificate} alt="Micromaster Certificate" width="584" height="414" className="micromasterCertificate" />
            </div>
            <div className="certificatesContainer">
                <img src={javaScriptCertificate} alt="Free Code Camp JavaScript Certificate" className="certificates" />
                <img src={responsiveWebCertificate} alt="Free Code Camp JavaScript Certificate" className="certificates" />
            </div>
        </div>
    )
}
export default certificates