import React from 'react'
import './Services.css';
import { TiBrush } from "react-icons/ti";
import { DiWordpress } from "react-icons/di";
import { MdApps } from "react-icons/md";

const Services = () => {
    return (
        <div className="mainSection">
            <h1 className="servicesHeading">Services</h1>
            <div className="dividers"></div>
            <div className="sections">
                <div className="outerCard">
                    <div className="card">
                        <TiBrush className="card-img-top devIcons2" />
                        <div className="card-body">
                            <h5 className="card-title">Bespoke</h5>
                            <p className="card-text">Bespoke websites built with JavaScript/React/NodeJS etc.</p>
                            <p>Please contact for details.</p>
                        </div>
                    </div>
                    <div className="card">
                        <DiWordpress className="card-img-top devIcons2" />
                        <div className="card-body">
                            <h5 className="card-title">CMS (WordPress/Shopify/Wix etc)</h5>
                            <p className="card-text">CMS websites built and deployed to your specification with many add on options available, to take it from start to finish.</p>
                            <p>Please contact for details.</p>
                        </div>
                    </div>
                    <div className="card">
                        <MdApps className="card-img-top devIcons2" />
                        <div className="card-body">
                            <h5 className="card-title">Apps</h5>
                            <p className="card-text">Custom applications or customisations to your current website/installed applications as required, for WordPress/Shopify/custom website. </p>
                            <p>Please contact for details.</p>
                        </div>
                    </div>
                </div>
                <p className="servicesP">As each project is different, please contact me with your project proposal to see if I am available to help, and for a quote and/or price-list options.</p>
            </div>
        </div>
    )
}
export default Services