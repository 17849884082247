import React from 'react';
import '../About.css';

const introduction = () => {
    return (
        <div className="sections aboutMe introductionDiv">
            <p>Hello and welcome.</p>
            <p>If you are a company looking to hire a developer, or looking for someone to help you build or modify an website/application, please consider contacting me with your proposal.</p>
            <p>I am enthusiastic about technology and science, and would love to hear from you!</p>
            <p>I can work with you remotely or in person.</p>
            <p>Please contact me with any requests or questions and I will respond in due course.</p>
        </div>
    )
}
export default introduction