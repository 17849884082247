import React from 'react';
import './Experience.css';
import { FaAws } from "react-icons/fa";
import { DiBootstrap } from "react-icons/di";
import { DiCss3 } from "react-icons/di";
import { DiGithubBadge } from "react-icons/di";
import { FaGitlab } from "react-icons/fa";
import { DiHaskell } from "react-icons/di";
import { DiHtml5 } from "react-icons/di";
import { DiJava } from "react-icons/di";
import { DiJsBadge } from "react-icons/di";
import { DiJqueryUiLogo } from "react-icons/di";
import { DiMysql } from "react-icons/di";
import { DiNodejs } from "react-icons/di";
import { DiPhp } from "react-icons/di";
import { DiPhotoshop } from "react-icons/di";
import { DiPostgresql } from "react-icons/di";
import { DiPython } from "react-icons/di";
import { DiReact } from "react-icons/di";
import { DiWordpress } from "react-icons/di";

const experience = () => {
    return (
        <div className="sections">
            <p className="devIconsBlurb">I have experience working with the programming languages and internet technologies shown in the icon links below, and I am willing and capable of learning new languages or technologies as required.</p>
            <div className="devIconsContainerLine1">
                <div className="devIconsBorder">
                    <a href="https://aws.amazon.com/" title="Amazon AWS" target="_blank" rel="noopener noreferrer" className="devIcons"><FaAws /></a>
                    <a href="https://getbootstrap.com/" title="Bootstrap" target="_blank" rel="noopener noreferrer" className="devIcons"><DiBootstrap /></a>
                    <a href="https://developer.mozilla.org/en-US/docs/Web/CSS" title="CSS" target="_blank" rel="noopener noreferrer" className="devIcons"><DiCss3 /></a>
                    <a href="https://github.com/" title="Github" target="_blank" rel="noopener noreferrer" className="devIcons"><DiGithubBadge /></a>
                    <a href="https://gitlab.com/" title="Gitlab" target="_blank" rel="noopener noreferrer" className="devIcons"><FaGitlab /></a>
                    <a href="https://www.haskell.org/" title="Haskell" target="_blank" rel="noopener noreferrer" className="devIcons"><DiHaskell /></a>
                    <a href="https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5" title="HTML5" target="_blank" rel="noopener noreferrer" className="devIcons"><DiHtml5 /></a>
                    <a href="https://www.java.com/en/" title="Java" target="_blank" rel="noopener noreferrer" className="devIcons"><DiJava /></a>
                    <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" title="JavaScript" target="_blank" rel="noopener noreferrer" className="devIcons"><DiJsBadge /></a>
                    <a href="https://jquery.com/" title="JQuery" target="_blank" rel="noopener noreferrer" className="devIcons"><DiJqueryUiLogo /></a>
                    <a href="https://www.mysql.com/" title="MySQL" target="_blank" rel="noopener noreferrer" className="devIcons"><DiMysql /></a>
                    <a href="https://nodejs.org/en/" title="NodeJS" target="_blank" rel="noopener noreferrer" className="devIcons"><DiNodejs /></a>
                    <a href="https://www.php.net/" title="PHP" target="_blank" rel="noopener noreferrer" className="devIcons"><DiPhp /></a>
                    <a href="https://www.adobe.com/hk_en/products/photoshop.html" title="Photoshop" target="_blank" rel="noopener noreferrer" className="devIcons"><DiPhotoshop /></a>
                    <a href="https://www.postgresql.org/" title="PostgreSQL" target="_blank" rel="noopener noreferrer" className="devIcons"><DiPostgresql /></a>
                    <a href="https://www.python.org/" title="Python" target="_blank" rel="noopener noreferrer" className="devIcons"><DiPython /></a>
                    <a href="https://reactjs.org/" title="ReactJS" target="_blank" rel="noopener noreferrer" className="devIcons"><DiReact /></a>
                    <a href="https://wordpress.com/" title="WordPress" target="_blank" rel="noopener noreferrer" className="devIcons"><DiWordpress /></a>
                </div>
            </div>
        </div>
    )
}
export default experience