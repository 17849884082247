import React, { Component } from "react"
import Typist from 'react-typist';
import './Home.css';
import UIfx from 'uifx'; 
import mp3File from '../../assets/audio/typewriter.mp3';

class Home extends Component {
    componentDidMount() {
        const typeAudio = new UIfx({asset: mp3File});
        typeAudio.play()
    }
    render() {
        return (
            <div className="Home">
                <section className="introduction">
                    <span className="intro-upper">Hello, my name is </span>
                    <span className="intro-name">Matthew Woodroffe</span>
                    <span className="intro-lower">
                        <span className="intro-text">I am a </span>
                        <span className="typography-setting">
                            <Typist avgTypingDelay={80}
                                startDelay={1000}>
                                Full Stack Developer
                            </Typist>
                        </span>
                    </span>
                </section>
            </div>
        );
    }
}

export default Home