import React, { Component } from 'react';
import './About.css';
import ProfilePic from '../../assets/images/profile.png'
import Certificates from './Certificates/Certificates';
import Experience from './Experience/Experience';
import Introduction from './Introduction/Introduction';

class About extends Component {
    state = {
        showIntroduction: true,
        showExperience: true,
        showCertificates: true,
    };
    clickHandlerIntroduction = () => {
        const showIntroductionValue = this.state.showIntroduction
        this.setState({
            showIntroduction: !showIntroductionValue,
        })
    };
    clickHandlerExperience = () => {
        const showExperienceValue = this.state.showExperience
        this.setState({
            showExperience: !showExperienceValue,
        })
    };
    clickHandlerCertificates = () => {
        const showCertificatesValue = this.state.showCertificates
        this.setState({
            showCertificates: !showCertificatesValue,
        })
    };
    render() {
        let showIntroductionIf = null;
        if (this.state.showIntroduction) {
            showIntroductionIf = <Introduction />
        }
        let showExperienceIf = null;
        if (this.state.showExperience) {
            showExperienceIf = <Experience />
        }
        let showCertificateIf = null;
        if (this.state.showCertificates) {
                showCertificateIf = <Certificates />
        }
        return (
            <div className="mainSection">
                <h1 className="aboutMeHeading">About Me</h1>
                <div className="dividers"></div>
                <img src={ProfilePic} alt="Profile" width="170" height="170" className="profilePic" />
                <div className="sections">
                    <h2 className="headers" onClick={this.clickHandlerIntroduction}>Introduction</h2>
                    {showIntroductionIf}
                </div>
                <div className="dividers"></div>
                <div className="sections">
                    <h2 className="headers" onClick={this.clickHandlerExperience}>Experience</h2>
                    {showExperienceIf}
                </div>
                <div className="dividers"></div>
                <div className="sections">
                    <h2 className="headers" onClick={this.clickHandlerCertificates}>Certificates</h2>
                    {showCertificateIf}
                </div>
            </div>
        )
    };

}
export default About