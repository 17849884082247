import React from 'react'
import './Contact.css';
import { MdEmail } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";

const Contact = () => {
    return (
        <div className="mainSection">
            <h1 className="contactHeading">Contact Me</h1>
            <div className="dividers"></div>
            <div className="sections centreIcons">
                <div className="iconDivOuter">
                    <div className="iconDiv">Email<a href="mailto:dev@matthewwoodroffe.com" className="contactIcons"><MdEmail /></a></div>
                </div>
                <div className="iconDivOuter">
                    <div className="iconDiv">Facebook<a href="https://www.facebook.com/matt.woodroffe.1/" target="_blank" className="contactIcons"><FaFacebook /></a></div>
                </div>
            </div>
        </div>
    )
}

export default Contact