import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithubSquare } from '@fortawesome/free-brands-svg-icons';
import Burger from './Burger/Burger';

class Navbar extends Component {
    state = {
        navLinks: <ul></ul>
    }
    componentDidMount() {
        let width = window.innerWidth;
        if (width > 768) {
            this.setState({
                navLinks:   <ul className="navLinks">
                                <li><NavLink to="/" exact className="navLink">Home</NavLink></li>
                                <li><NavLink to="/about" className="navLink">About</NavLink></li>
                                <li><NavLink to="/services" className="navLink">Services</NavLink></li>
                                <li><NavLink to="/contact" className="navLink">Contact</NavLink></li>
                            </ul>
            })
        } else {
            this.setState({
                navLinks:   <Burger />
            })
        }
    }
    render() {
        return (
            <div className="navbarContainer">
                <div className="navbarContent">
                    <div className="logo">
                        <h2>
                            <a href="https://github.com/atchoo" target="_blank" rel="noopener noreferrer" className="navLink githubLink" ><FontAwesomeIcon icon={faGithubSquare} />Github</a>
                        </h2>
                    </div>
                    {this.state.navLinks}
                </div>
            </div>
        );
    }
}

export default Navbar