import React from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 18px 10px;
  }
  @media (max-width: 768px) {
    z-index: 19;
    flex-flow: column nowrap;
    background-color: #f2f2f2;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    ${'' /* opacity: 1; */}
    li {
      color: #fff;
    }
  }
`;

const RightNav = ({ open }) => {
    return (
        <Ul open={open}>
            <li><NavLink to="/" exact className="navLink">Home</NavLink></li>
            <li><NavLink to="/about" className="navLink">About</NavLink></li>
            <li><NavLink to="/services" className="navLink">Services</NavLink></li>
            <li><NavLink to="/contact" className="navLink">Contact</NavLink></li>
        </Ul>
    )
}

export default RightNav